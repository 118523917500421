@use '../settings/colors';

.quotes {
    width: calc(100% + 3rem + 40px);

    @include mq($from: md) {
        width: 225px;
        margin-left: -3rem;
    }

    @include mq($from: lg) {
        width: 350px;
    }
}

.quotes__item {
    margin-right: 4rem;
    margin-bottom: 0.5rem;
    margin-left: 4rem;
    line-height: 1.7rem;

    @include mq($from: md) {
        margin-right: 0;
        margin-left: 4.5rem;
    }
}

.quotes__icon {
    width: 1rem;
    height: 1rem;
    fill: colors.$brand-primary;
}

.quotes__text {
    position: relative;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: 700;
}

.quotes__author {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 1rem;
        right: 100%;
        left: -4rem;
        display: block;
        width: 2rem;
        height: 1px;
        background-color: colors.$brand-primary;
    }

    @include mq($from: md) {
        &:before {
            left: -4.5rem;
            width: 3rem;
        }
    }
}

.quotes__author-name {
    display: inline-block;

    &:after {
        content: ',';
    }
}
