@use '../settings/typography';
@use '../settings/colors';

.authors {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include mq($from: md) {
        align-items: center;
        justify-content: center;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    @include mq($from: lg) {
        flex-wrap: nowrap;
    }
}

.authors__author-info,
.authors__image-holder {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
}

.authors__author-info {
    width: 50%;
}

.authors__author-info--left {
    order: 2;
    padding: 1.5rem 0.5rem 1.5rem 0;
    text-align: right;

    @include mq($from: md) {
        padding: 1.5rem;
    }

    @include mq($from: lg) {
        order: unset;
    }
}

.authors__author-info--right {
    order: 3;
    padding: 1.5rem 0 1.5rem 0.5rem;

    @include mq($from: md) {
        padding: 1.5rem;
    }

    @include mq($from: lg) {
        order: unset;
    }
}

.authors__image-holder {
    display: block;
    flex-shrink: 0;
    order: 1;
    justify-content: center;
    width: 100%;
    text-align: center;

    @include mq($from: lg) {
        order: unset;
        width: 350px;
    }

    @include mq($from: xl) {
        width: 450px;
    }
}

.authors__image {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    overflow: hidden;

    @include mq($from: md) {
        width: 250px;
        height: 250px;
    }

    @include mq($from: xl) {
        width: 350px;
        height: 350px;
    }
}

.authors__name {
    font-family: typography.$heading-font-family;
    text-transform: uppercase;
}

.authors__contact-link {
    display: inline-block;
    margin-right: 1rem;
    text-decoration: none;

    &:last-child {
        margin-right: 0;
    }

    .authors__author-info--left & {
        margin-right: 0;
        margin-left: 1rem;

        &:first-child {
            margin-left: 0;
        }
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
