$brand-primary: #003459;
$brand-secondary: #00a6fb;
$brand-tertiary: #fad9e7;
$brand-tertiary-highlight: #f58;

$body-color: #003459;
$body-bg: #fff;
$link-color: $brand-secondary;
$line-color: #ccc;
$heading-color: inherit;
$form-bg-color: #f6f6f8;
