@use '../settings/colors';
@use '../tools/animations';

$newsletter-subscription-height: 500px;
$newsletter-subscription-height--from-md: 420px;
$newsletter-subscription-side-slantness: 60px;

.newsletter-subscription-container {
    margin-top: 5rem;
    padding: 5rem 0 3rem;
    background-color: colors.$brand-tertiary;
    animation: fadeIn 1s;
}

.newsletter-subscription {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $newsletter-subscription-height;
    margin-top: -10rem;
    background-color: #fff;
    box-shadow: 0 15px 50px -20px rgba(255, 86, 137, 0.8);

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        border-bottom: $newsletter-subscription-height solid #fff;
        overflow: hidden;
    }

    &:before {
        right: 100%;
        border-left: $newsletter-subscription-side-slantness solid transparent;
    }

    &:after {
        left: 100%;
        border-right: $newsletter-subscription-side-slantness solid transparent;
    }

    @include mq($from: sm) {
        padding: 1.5rem;
    }

    @include mq($from: md) {
        height: $newsletter-subscription-height--from-md;

        &:before,
        &:after {
            border-bottom: $newsletter-subscription-height--from-md solid #fff;
        }
    }
}

.newsletter-subscription__content {
    text-align: center;

    p {
        margin-bottom: 1.5rem;
        font-size: 1.1rem;
    }

    @include mq($from: md) {
        width: percentage(7/12);
    }
}

.newsletter-subscription__fake-form-field {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    border-radius: 100px;
    background-color: colors.$form-bg-color;
    box-shadow: inset 0 6px 12px -15px colors.$brand-primary;

    .form__group {
        width: 100%;
        margin-bottom: 0;
    }

    .form-field {
        padding: 0.75rem 1rem;
        border-radius: 100px;
        box-shadow: none;
        background-color: transparent;
    }
}

.newsletter-subscription__button {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    @include mq($from: sm) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        font-size: 1.1rem;
    }

    @include mq($from: xl) {
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 1.3rem;
    }
}

.newsletter-subscription__button-icon {
    display: block;
    margin-right: 0;

    @include mq($from: sm) {
        display: none;
        margin-right: 1rem;
    }
}

.newsletter-subscription__button-icon--from-md {
    display: none;

    @include mq($from: sm) {
        display: block;
    }
}

.newsletter-subscription__button-text {
    display: none;

    @include mq($from: sm) {
        display: inline;
    }
}
