@use '../settings/colors';

.page-detail-main {
    display: flex;
    justify-content: center;
    padding: 2.5rem 0;
    background-color: colors.$body-bg;

    @include mq($from: md) {
        padding: 5rem 0;
    }
}

.page-detail-main__content {
    @include mq($from: md) {
        width: percentage(7/12);
    }
}
