@use '../settings/colors';
@use '../settings/typography';

.main-nav-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
}

.main-nav-container--is-open {
    position: fixed;
    z-index: $zindex-main-nav-container-open;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 0.5rem;
    padding-right: 20px;
    padding-left: 20px;
    background-color: $brand-primary;
}

.main-nav {
    display: none;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    .main-nav-container--is-open & {
        display: flex;
    }

    @include mq($from: md) {
        display: flex;
        flex-direction: row;
    }
}

.main-nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 1rem;
    text-decoration: none;
    color: colors.$brand-primary;
    font-size: 1.3rem;
    font-weight: 400;

    &:after {
        content: '';
        display: block;
        flex-shrink: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
    }

    &:hover,
    &:focus,
    &.main-nav__link--active {
        &:after {
            background-color: colors.$brand-primary;
        }
    }

    .main-nav-container--is-open & {
        color: colors.$brand-tertiary;

        &:hover,
        &:focus,
        &.main-nav__link--active {
            &:after {
                background-color: colors.$brand-tertiary;
            }
        }
    }

    @include mq($from: md) {
        padding: 1.25rem 1rem 1rem;
        font-size: 1rem;
    }

    @include mq($from: xl) {
        font-size: 1.1rem;
    }
}

.main-nav__button {
    position: absolute;
    top: 50%;
    right: 20px;
    padding: 0.225rem 0.75rem;
    transform: translateY(-50%);
    font-size: 0.9rem;
    font-family: typography.$heading-font-family;
    box-shadow: 0 4px 3px -3px rgba(0, 52, 89, 0.2),
        0 -4px 3px -3px rgba(0, 52, 89, 0.15);

    .main-nav-container--is-open & {
        display: none;
    }

    @include mq($from: md) {
        right: 3rem;
        padding: 0.625rem 1rem;
        font-size: 1rem;
    }

    @include mq($from: 2005px) {
        right: 0;
    }
}
