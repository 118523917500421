.text-block {
    position: relative;
    display: flex;
    justify-content: center;
}

.text-block__content {
    padding: 0 1rem 2.5rem;

    @include mq($from: md) {
        width: percentage(10/12);
    }

    @include mq($from: lg) {
        width: percentage(7/12);
        padding: 0 1.5rem 5rem;
    }
}

// @todo is not modifier on `text_block__content` but feels like it should be some Element in a .about-introduction Block (ie. `.about-introduction--text-block-content`)
.text-block__content--about {
    padding: 5rem 1rem 2.5rem;

    @include mq($from: lg) {
        width: percentage(7/12);
        padding: 7.5rem 1.5rem 5rem;
    }
}
