@use '../settings/colors';

.slick-dots {
    margin-left: 4rem;
    text-align: left;

    li {
        margin: 0;

        button:before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: colors.$brand-tertiary;
            opacity: 1;
        }

        &.slick-active button:before {
            background-color: colors.$brand-primary;
            opacity: 1;
        }
    }

    @include mq($from: md) {
        margin-left: 4.5rem;
    }
}
