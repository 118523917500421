// Settings
@import '~@freshheads/maatcss/settings/breakpoints';
@import 'settings/zindex';
@import 'settings/colors';
@import 'settings/typography';
@import 'settings/form';

// Libs
@import '~sass-mq';
@import '~normalize.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// Tools
@import '~@freshheads/maatcss/tools/button-variant';

// Generic
@import '~@freshheads/maatcss/generic/reboot';
@import '~@freshheads/maatcss/generic/toplevel';
@import '~@freshheads/maatcss/generic/typography';
@import 'generic/toplevel';
@import 'generic/fonts';

// Layout
@import '~@freshheads/maatcss/layout/container';
@import '~@freshheads/maatcss/layout/layout';
@import '~@freshheads/maatcss/layout/layout-sizing';
@import '~@freshheads/maatcss/layout/layout-align';
@import 'layout/layout';
@import 'layout/header';
@import 'layout/footer';

// Components
@import '~@freshheads/maatcss/components/button';
@import '~@freshheads/maatcss/components/form';
@import '~@freshheads/maatcss/components/form-field';
@import '~@freshheads/maatcss/components/form-choice';
@import 'components/button';
@import 'components/hero';
@import 'components/heading';
@import 'components/main-nav';
@import 'components/footer-nav';
@import 'components/main-nav-trigger';
@import 'components/homepage-intro';
@import 'components/intro';
@import 'components/page-detail-main';
@import 'components/separator';
@import 'components/quotes';
@import 'components/text-block';
@import 'components/slanted-container';
@import 'components/arrow';
@import 'components/authors';
@import 'components/modal';
@import 'components/form';
@import 'components/form-field';
@import 'components/form-choice';
@import 'components/message';
@import 'components/slick-slider';
@import 'components/newsletter-subscription';
@import 'components/carousel';
@import 'components/cookies';
@import 'components/book-preview';
@import 'components/redactor-content';
@import 'components/parallax';
