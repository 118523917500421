@use '../settings/colors';

.cookie__banner {
    position: fixed;
    z-index: $zindex-cookie-banner;
    bottom: 2rem;
    width: 100%;

    p {
        margin-right: 1rem;
        font-size: 0.8rem;
    }

    a {
        border: 0;
        color: colors.$brand-secondary;
    }

    button {
        margin-left: 1rem;
        line-height: 1;
    }

    .container {
        @include mq($from: lg) {
            max-width: 70%;
        }
    }
}

.cookie__banner-content {
    width: 100%;
    padding: 1.25rem;
    border-radius: 6px;
    background-color: colors.$brand-primary;
    color: #fff;

    .button-wrapper {
        text-align: center;

        @include mq($from: xl) {
            display: flex;
            margin-left: 1rem;
            text-align: left;
        }
    }

    @include mq($from: xl) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
        }
    }
}
