@use '../settings/colors';

.footer-nav {
    width: 100%;
    margin: 0 0 1.5rem;
    padding: 0;
    list-style: none;

    @include mq($from: sm) {
        width: auto;
        margin-bottom: 0;
    }
}

.footer-nav__item {
    display: block;
    margin-bottom: 0.5rem;

    @include mq($from: md) {
        display: inline-block;
        margin-right: 3rem;
        margin-bottom: 0;
    }
}

.footer-nav__link {
    text-decoration: none;
    color: colors.$brand-primary;

    @include mq($from: md) {
        font-size: 1.1rem;
    }
}
