@use '../settings/colors';
@use '../settings/typography';

.button {
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border-radius: 100px;
    outline: none;
    color: #fff;
    fill: #fff;
    background-color: colors.$brand-secondary;

    &:hover,
    &:focus {
        outline: none;
        background-color: mix(#000, colors.$brand-secondary, 5%);
    }
}

.button--tertiary-highlight {
    background-color: colors.$brand-tertiary-highlight;

    &:hover,
    &:focus {
        background-color: mix(#000, colors.$brand-tertiary-highlight, 5%);
    }
}

.button--big {
    font-family: typography.$heading-font-family;
    box-shadow: 0 4px 3px -3px rgba(0, 52, 89, 0.2),
        0 -4px 3px -3px rgba(0, 52, 89, 0.15);

    @include mq($from: md) {
        padding-left: 5rem;
        padding-right: 5rem;
        font-size: 1.3rem;
    }
}

.button--anchor {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
    color: colors.$brand-secondary;
    fill: colors.$brand-secondary;
    background-color: transparent;

    &:hover,
    &:focus {
        background-color: transparent;
    }
}

.button__icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.button__icon--right {
    margin-right: 0;
    margin-left: 1rem;
}

.button__arrow {
    margin-left: 1rem;

    .button--with-arrow:hover &,
    .button--with-arrow:focus & {
        width: 55px;
    }
}

.button-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-bottom: -1rem;

    .button {
        margin-bottom: 1rem;
    }

    @include mq($from: md) {
        flex-wrap: nowrap;
    }
}
