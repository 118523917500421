@use '../settings/colors';
@use '../tools/animations';

.footer-container {
    padding: 0.5rem 0 3rem;
    background-color: colors.$brand-tertiary;
    animation: fadeIn 1s;
}

.footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include mq($from: md) {
        flex-wrap: nowrap;
    }
}

.footer__logo {
    display: block;

    @include mq($from: sm) {
        margin-left: auto;
    }
}
