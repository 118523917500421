@use '../settings/colors';

.header {
    position: fixed;
    z-index: $zindex-header;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem 0;
    background-color: colors.$brand-tertiary;

    @include mq($from: md) {
        min-height: auto;
        padding: 0;
    }
}
