@use '../settings/colors';

.redactor-content {
    ul {
        padding-left: 1rem;
    }

    p:last-child {
        margin-bottom: 0;
    }

    figure {
        position: relative;
        width: calc(100% + 4rem);
        padding: 0 2rem 1.5rem;
        margin-right: -2rem;
        margin-left: -2rem;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 1.5rem;
            left: 0;
            width: 100%;
            height: 0;
            border-right: 25px solid transparent;
            border-bottom: 750px solid colors.$brand-tertiary;
            border-left: 25px solid transparent;
        }

        img {
            position: relative;
            z-index: $zindex-redactor-content-img;
            width: 100%;
            box-shadow: 0 10px 20px 0 rgba(255, 86, 137, 0.2);
        }
    }

    blockquote {
        display: block;
        padding-top: 1rem;
        color: colors.$brand-secondary;
        font-size: 1.3rem;
        font-weight: 700;

        &:before {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            margin-bottom: 1rem;
            background-image: url('../../../images/icons/quote--secondary.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
