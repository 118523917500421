@use '../settings/colors';

.slanted-container {
    position: relative;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        border-bottom: 25vw solid colors.$brand-tertiary;
        transform: rotate(180deg);
    }

    &:before {
        left: 0;
        border-left: 10vw solid transparent;
    }

    &:after {
        right: 0;
        border-right: 10vw solid transparent;
    }
}

.slanted-container__line {
    position: absolute;
    top: -2.5rem;
    left: 50%;
    display: block;
    width: 3px;
    height: 5rem;
    transform: translate(-50, -50%);
    background-color: colors.$brand-primary;
}
