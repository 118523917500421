$base-font-family: 'Neutrif', sans-serif;
$heading-font-family: 'ProximaNovaExtraBold', sans-serif;
$heading-font-weight: normal;
$base-line-height: 1.75;
$font-size-h1: 2.4rem;
$font-size-h2: 2rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.3rem;
$font-size-h5: 1.1rem;
$font-size-h6: 1rem;

@import '~@freshheads/maatcss/settings/typography';
