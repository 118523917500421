@use '../settings/colors';
@use '../settings/typography';

.hero-container {
    display: flex;
    justify-content: center;
    padding-bottom: 2.5rem;
    background-color: colors.$brand-tertiary;
}

.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    background-color: #fff;

    @include mq($from: md) {
        width: calc(100% - 6rem);
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

.hero__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 35vw;
    padding: 1.5rem 1.5rem 3rem;

    @include mq($from: md) {
        flex-direction: row;
        padding: 3rem 1.5rem;
    }
}

.hero__image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;

    @include mq($from: md) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 1rem;
    }
}

.hero__sticker {
    position: absolute;
    top: 5rem;
    left: calc(50% + 4.7rem);
    width: 8.5rem;
    transform: translate(-50%, -50%);

    @include mq($from: md) {
        top: 34%;
        right: 0;
        left: calc(50% + 8rem);
        width: 10.5rem;
    }

    @include mq($from: xl) {
        top: 30%;
        left: 58vw;
        width: 13.5rem;
    }

    @include mq($from: 2200px) {
        left: calc(50% + 17rem);
        width: 18rem;
    }
}

.hero__image {
    display: block;
    max-width: 230px;

    @include mq($from: md) {
        max-width: unset;
        height: 100%;
        max-height: 375px;
    }

    @include mq($from: lg) {
        max-height: none;
    }
}

.hero__button {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -26px;
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.1rem;

    @include mq($from: md) {
        margin-bottom: -32px;
        font-size: 1.5rem;
    }
}

.hero__button-small-text {
    display: block;
    width: 100%;
    margin-top: -5px;
    font-family: typography.$base-font-family;
    font-size: 0.9rem;
    line-height: 1.1rem;
}
