.form-group-splitter {
    @include mq($from: md) {
        display: flex;
        width: calc(100% + 1.5rem);
        margin-right: -1.5rem;
    }
}

.form__group {
    @include mq($from: md) {
        .form-group-splitter & {
            flex-shrink: 0;
            width: calc(50% - 1.5rem);
            margin-right: 1.5rem;
        }
    }
}

.form__label {
    font-weight: 700;
}

.form__post-information {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
}
