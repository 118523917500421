@use '../settings/colors';

.modal-is-open {
    height: 100vh;
    overflow-y: hidden;
}

.modal-overlay {
    position: fixed;
    z-index: $zindex-modal-overlay;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(colors.$brand-primary, 0.8);
}

.modal {
    position: relative;
    width: 900px;
    max-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
    overflow-y: auto;
    background-color: #fff;
}

.modal__close {
    position: absolute;
    z-index: $zindex-modal-close-button;
    top: 1.5rem;
    right: 1.5rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: transparent;

    &:hover,
    &:focus {
        outline: none;
        background-color: #f1f1f1;
    }
}

.modal__close-icon {
    width: 1rem;
    height: 1rem;
    fill: colors.$brand-primary;
}

.modal__main {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 1rem;

    @include mq($from: sm) {
        flex-wrap: nowrap;
        padding: 3rem 4.5rem;
    }
}

.modal__image-holder {
    flex-grow: 0;
    flex-shrink: 0;
    width: 150px;
    min-width: 70px;
    margin-bottom: 1.5rem;
    // Negative margin to even out the space created by the shadow in the image
    margin-left: -2rem;

    @include mq($from: sm) {
        width: 130px;
        margin-bottom: 0;
        margin-right: 1rem;
    }
}

.modal__content {
    p {
        font-size: 0.9rem;
    }
}
