@use '../settings/colors';

.parallax__holder {
    position: relative;
    z-index: $zindex-parallax-text-holder;
    width: 100%;
    overflow: hidden;
    text-transform: uppercase;
    opacity: 0.4;

    h2 {
        margin-top: -5vw;
        margin-bottom: -4.25vw;
        font-size: 19vw;
        color: colors.$brand-tertiary;
    }
}

.parallax__inner {
    width: calc(100% + 28vw);
    margin-left: -7vw;
}
