@use '~@freshheads/maatcss/settings/typography';
@use '../settings/colors';

.heading {
    font-weight: 500;
    color: colors.$heading-color;
}

.heading--uppercase {
    text-transform: uppercase;
}

.heading--flatten {
    margin: 0;
}

.heading--centered {
    text-align: center;
}

.heading--semi-bold {
    font-weight: 600;
}

.heading--extra-bold {
    font-weight: 900;
}

.heading--size-variant-h1 {
    font-size: typography.$font-size-h1;
}

.heading--size-variant-h2 {
    font-size: typography.$font-size-h2;
}

.heading--size-variant-h3 {
    font-size: typography.$font-size-h3;
}

.heading--size-variant-h4 {
    font-size: typography.$font-size-h4;
}

.heading--size-variant-h5 {
    font-size: typography.$font-size-h5;
}

.heading--size-variant-h6 {
    font-size: typography.$font-size-h6;
}

.heading--size-variant-base {
    font-size: typography.$base-font-size;
}
