@use '../settings/colors';

.arrow {
    position: relative;
    display: block;
    width: 45px;
    height: 24px;
    transition: width 100ms ease-in-out;
}

.arrow__line {
    position: absolute;
    top: calc(50%);
    display: block;
    width: 100%;
    height: 1px;
    transform: translateY(-50%);
    background-color: colors.$brand-secondary;
}

.arrow__head {
    position: absolute;
    top: 1px;
    right: 6px;
    display: inline-block;
    padding: 6px;
    border: solid colors.$brand-secondary;
    border-width: 0 1px 1px 0;
    transform: rotate(-45deg) translateY(50%);
}
