.carousel {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1;
    white-space: nowrap;
}

.carousel__holder {
    position: absolute;
    top: 0;
    height: 120px;
    transform: translateY(-100%);
    font-size: 0;
    line-height: 0;
}

.carousel__item {
    display: inline-block;
    opacity: 0.3;
    font-size: 120px;
    color: $brand-tertiary;
    text-transform: uppercase;
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;

    &:after {
        content: '—';
        position: relative;
        top: -25px;
    }
}

@include mq($from: sm) {
    .carousel__item {
        font-size: 200px;
    }

    .carousel__holder {
        height: 200px;
    }
}

@include mq($from: lg) {
    .carousel__item {
        font-size: 300px;
    }

    .carousel__holder {
        height: 150px;
    }
}
