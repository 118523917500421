@use '../settings/colors';

.main-nav-trigger {
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    outline: none;
    background-color: unset;
    color: colors.$brand-primary;

    &:active,
    &:hover,
    &:focus {
        outline: none;
    }

    .main-nav-container--is-open & {
        margin-bottom: 0.5rem;
        color: colors.$brand-tertiary;
    }

    @include mq($from: md) {
        display: none;

        .main-nav-container--is-open & {
            display: flex;
        }
    }
}

.main-nav-trigger__hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 2.5rem;
    height: 2rem;
    margin-right: 0.5rem;
}

.main-nav-trigger__hamburger-line {
    width: 100%;
    height: 0;
    border-right: 5px solid transparent;
    border-bottom: 5px solid colors.$brand-primary;
    border-left: 5px solid transparent;
    transition: all 0.15s;
    transition-property: top, width, transform;

    &:first-child {
        transform: translateY(-4px);
    }

    &:last-child {
        transform: translateY(4px);
    }

    .main-nav-container--is-open & {
        border-bottom-color: colors.$brand-tertiary;

        &:first-child {
            transform: translateY(5px);
        }

        &:last-child {
            transform: translateY(-5px);
        }
    }
}
