@use '../settings/colors';

.intro {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    padding: 2.5rem 0;

    @include mq($from: md) {
        padding: 5rem 0;
    }
}

.intro--left-aligned {
    justify-content: flex-start;
}

.intro__content {
    text-align: center;

    p {
        font-size: 1.15rem;
    }

    p:last-child {
        margin-bottom: 0;
    }

    @include mq($from: md) {
        width: percentage(7/12);

        p {
            font-size: 1.3rem;
        }
    }

    @include mq($from: lg) {
        width: percentage(5/12);
    }

    .intro--left-aligned & {
        text-align: left;
    }
}
