@use '../settings/colors';

.form-choice__label {
    display: inline-block;
    font-size: 0.9rem;

    &:before {
        display: inline-block;
        vertical-align: middle;
        border-radius: 5px;
        border: 1px solid $line-color;
        box-shadow: none;
        background-color: colors.$form-bg-color;
        background-size: 75% 75%;
    }

    .form-choice__input:focus ~ &,
    .form-choice__input:checked ~ & {
        &:before {
            border: 1px solid $line-color;
            box-shadow: none;
        }
    }
}
