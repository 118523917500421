.book-preview {
    position: relative;
    display: block;
    justify-content: center;
    margin-bottom: 10rem;
}

.book-preview__button {
    position: absolute;
    z-index: $zindex-book-preview-button;
    top: 50%;
    left: 50%;
    justify-content: center;
    width: calc(100% - 2rem);
    transform: translate(-50%, -50%);

    @include mq($from: sm) {
        width: auto;
    }
}

.book-preview__image-holder {
    position: relative;
    margin: 0 auto;

    @include mq($from: xl) {
        max-width: 1200px;
    }
}

.book-preview__image {
    display: block;
}
