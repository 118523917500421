@font-face {
    font-family: 'ProximaNovaExtraBold';
    src: url('../../../fonts/proxima-nova-extra-bold.woff2') format('woff2'),
        url('../../../fonts/proxima-nova-extra-bold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif';
    src: url('../../../fonts/neutrif-studio-regular.woff2') format('woff2'),
        url('../../../fonts/neutrif-studio-regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif';
    src: url('../../../fonts/neutrif-studio-bold.woff2') format('woff2'),
        url('../../../fonts/neutrif-studio-bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}
