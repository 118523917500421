@use '../settings/colors';

.homepage-intro {
    background-color: colors.$brand-tertiary;

    .intro {
        padding: 2.5rem 0 5rem;
        margin-bottom: 0;
    }
}
